.button {
  border-radius: 25px;
  max-width: 70%;
  margin: 0 auto;
  font-weight: bolder;
  font-size: x-large;
  letter-spacing: 1px;
}

.alert {
  font-weight: bolder;
  font-size: large;
  letter-spacing: 1px;
}

.resultText {
  font-weight: bolder;
  font-size: large;
  margin: 1% auto;
}

body {
  font-family: 'Mulish', sans-serif !important;
}
